<template>
  <VCard class="px-2 pb-2 mb-2">
    <VRow class="align-center pa-2" no-gutters justify="space-between">
      <VCol cols="auto" class="text-left d-flex align-center">
        <span class="font-weight-medium text-h6 mr-1">
          {{ $t("landing.adminPanel.contacts") }}
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              color="primary"
              small
              class="btn-edit sm"
              @click="chartDialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon dense>mdi-chart-bar</VIcon>
            </VBtn>
          </template>
          <span>{{ $t("crm.statsTitle") }}</span>
        </v-tooltip>
      </VCol>
      <VCol cols="auto" class="text-right">
        <VBtn
          depressed
          @click="createDialog = true"
          class="success-bg text-white text-transform-none br-10"
        >
          <VIcon :left="!$vuetify.breakpoint.xs">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.xs">
            {{ $t("landing.btn.createRequest") }}
          </div>
        </VBtn>
      </VCol>
    </VRow>
    <VDivider />
    <div>
      <!-- start -->
      <v-simple-table
        class="my-table"
        :dense="!isLarge"
        :height="getTableHeight">
        <template v-slot:default>
          <thead>
            <RequestFilters :filter="filter" :states="states" />
          </thead>
          <tbody>
            <request-row
              :states="states"
              :request="request"
              v-for="request in requests"
              :key="request.uuid"
              @toggleRow="toggleRow"
              :class="{ 'active-row': activeRow == request.uuid }"
            />
            <tr>
              <td
                colspan="10"
                v-intersect.quiet="nextPageHandler"
                v-if="!loadMoreDisabled && !loading"
              >
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </td>
            </tr>
            <tr v-if="!requests.length">
              <td colspan="10">
                <div class="text-center">
                  <h3>{{ $t("table.noData") }}</h3>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <RequestDialog type="create" :states="states" :visible="createDialog" @close="onCloseCreateRequest" :editRequestTitle=false />
    <JoinRequestsChart :visible="chartDialog" @close="onCloseChartDialog" v-if="chartDialog" />
    <div class="scroll-up__wrapper">
      <v-fab-transition>
        <VBtn
          v-if="isShowUpBtn"
          color="primary"
          small
          absolute
          bottom
          right
          fab
          @click="onScrollToTop"
          style="bottom: 0; right: 30px;"
        >
          <VIcon>mdi-chevron-up</VIcon>
        </VBtn>
      </v-fab-transition>
    </div>
  </VCard>
</template>

<script>
import RequestFilters from "./request-filters/requestFilters.vue";
import SizeUi from "@/mixins/SizeUi";
import loader from "@/mixins/loader";
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import RequestRow from "./request-content/requestRow.vue";
import depotService from "@/services/request/depot/depotService";
import RequestDialog from "./dialogs/RequestDialog.vue";
import EventBus from "@/events/EventBus";
import newRequests from '@/mixins/newRequestsMixin'
import JoinRequestsChart from "./dialogs/JoinRequestsChart.vue";
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  data: () => ({
    states: [],
    requests: [],
    loadMoreDisabled: false,
    loading: false,
    isLoadingFilter: false,
    createDialog: false,
    chartDialog: false,
    page: 1,
    firstNumber: "",
    secondNumber: "",
    perPageList: [5, 10, 15],
    windowHeight: document.documentElement.clientHeight,
    inputFilter: {},
    filter: {
      name: "",
      phone: "",
      description: "",
      email: ""
    },
    activeRow: null,
    isShowUpBtn: false,
    loadTime: 0
  }),
  beforeDestroy(){
    const elem = document.querySelector(".v-data-table__wrapper");
    elem.removeEventListener("scroll", this.handleScroll);
    EventBus.$off('requests-modified')
  },
  async mounted() {
    if (!this.$vuetify.breakpoint.smAndDown) {
      const elem = document.querySelector(".v-data-table__wrapper");
      elem.addEventListener("scroll", this.handleScroll);
    }
    EventBus.$on("requests-modified", async () => {
      this.page = 1;
      if(this.filter){
        await this.fetchRequests(this.inputFilter, false, true);
        await this.fetchAmount();
      }
      else{
        await this.fetchRequests();
        await this.fetchAmount()
      }

    });
    this.setLoading(true);
    await this.fetchRequests();
    await this.getStates();
    await this.fetchAmount();
    this.checkLoadTime();
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    onCloseCreateRequest() {
      this.createDialog = false;
    },
    onCloseChartDialog(){
      this.chartDialog = false;
    },
    async getStates() {
      this.states = await depotService.getAllStates({
        type: "JOIN_REQUEST"
      });
    },
    toggleRow(e) {
      this.activeRow = e;
    },
    async nextPage(updated) {
      this.page += 1;
      await this.fetchRequests(this.inputFilter, true, true);
    },
    nextPageHandler: _.debounce(function (entries, observer, isIntersecting) {
      if (entries[0].isIntersecting) {
        console.info("[NS]: Is intersect ns code list");
        this.nextPage();
      }
    }, 100),
    reorderNumbers() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
    },
    async fetchRequests(params = {}, next = false, uselike = false, updated) {
      try {
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.loading = true;
        const newItems = await joinRequestService.getJoinRequestList({
          ...params,
          order: "DESC",
          orderby: "time_created"
        });

        if (next) {
          this.requests.push(...newItems);
        } else {
          this.requests = newItems;
        }

        this.setLoading(false);
        this.loading = false;
        this.loadMoreDisabled = newItems.length < this.perPage;
      } catch (e) {
        this.loading = false;
        this.setLoading(false);
        console.log(e)
      }
    },
    getWindowHeight: _.debounce(function () {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    onScrollToTop() {
      this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
    },
    handleScroll(e) {
      if (document.querySelector(".v-data-table__wrapper").scrollTop > 750) {
        this.isShowUpBtn = true;
      } else {
        this.isShowUpBtn = false;
      }
    },
  },
  watch: {
    page(e) {
      if (this.perPage * this.page >= 500 && this.perPage * this.page <= 500 + this.perPage) {
        this.pageBeforeLimit = e;
      }
    },
    async perPage() {
      await this.fetchRequests();
    },
    filter: {
      deep: true,
      handler: _.debounce(async function() {
        const params = {
          uselike: true
        };
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            params[key] = this.filter[key];
          }
        });
        this.inputFilter = params;

        if (params.phone) {
          params.phone = params.phone.replace(/[^+\d]/g, "");
        }

        this.page = 1;
        this.qwerty = params;
        await this.fetchRequests(params, false, true);
      }, 500)
    }
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    getTableHeight() {
      return this.windowHeight - 170 + "px";
    },
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 500) {
        this.page = this.pageBeforeLimit - 1;
        this.$scrollTop();
        return 500;
      }
      return this.perPage * this.page;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    },
    nextPageDisabled() {
      return this.requests.length < this.perPage;
    },
  },
  components: {
  RequestFilters,
  RequestRow,
  RequestDialog,
  JoinRequestsChart
},
  mixins: [SizeUi, loader, newRequests],
};
</script>

<style></style>
