<template>
  <v-dialog
    v-model="visibility"
    max-width="600px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog"
  >
    <v-card :loading="loading">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <v-card-title>
        <v-row class="mx-1" align="center">
          <v-col>
            <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }"
              >{{ editRequestTitle ? $t("landing.btn.editRequest") : $t("landing.btn.createRequest") }}
            </span>
          </v-col>
          <v-col>
            <VSelect
              dense
              :placeholder="$t('btn.all')"
              :items="Object.values(states)"
              :item-text="getStatusText"
              :item-value="'id'"
              hide-details
              solo
              v-model="requestData.id_state"
              class="text-caption"
              :disabled="type === 'create'"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                  <div class="elispsis">
                    {{ getStatusText(item) }}
                  </div>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                  <div class="elispsis">
                    {{ getStatusText(item) }}
                  </div>
                </div>
              </template>
            </VSelect>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mx-5"></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <VSelect
                dense
                :placeholder="$t('crm.choose_state')"
                :items="statusOptions"
                item-text="text"
                item-value="value"
                hide-details
                solo
                v-model="requestData.status"
                class="text-caption"
                :disabled="type === 'create'"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-content-start ">
                    <div :class="getStatusColorClass(item.value)" class="status-marker"></div>
                    <div class="elispsis">
                      {{ item.text }}
                    </div>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center justify-content-start ">
                    <div :class="getStatusColorClass(item.value)" class="status-marker"></div>
                    <div class="elispsis">
                      {{ item.text }}
                    </div>
                  </div>
                </template>
              </VSelect>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-103"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="text-caption"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    v-model="requestData.time_last_contact"
                    readonly
                    append-icon="mdi-calendar"
                    solo
                    :placeholder="$t('form.time_last_contact')"
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    :disabled="type === 'create'"
                  />
                </template>
                <v-date-picker
                  :locale="currentLanguage.code"
                  no-title
                  min="2021"
                  :max="NextYear"
                  v-model="requestData.time_last_contact"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="">
              <VTextField
                outlined
                dense
                :label="$t('form.name')"
                required
                v-model="requestData.name"
                :error-messages="nameErrors"
                hide-details="auto"
                color="success"
                v-on:keypress="validationForName($event)"
                @paste="validationForName($event)"
              ></VTextField>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <VTextField
                class="inputPhone"
                outlined
                dense
                :label="$t('form.phone_number')"
                required
                v-model="requestData.phone"
                :error-messages="phoneErrors"
                prefix="+38"
                :maxLength="20"
                hide-details="auto"
                color="success"
                v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
              >
              </VTextField>
            </v-col>
            <v-col cols="12" class="">
              <VTextField
                outlined
                dense
                :label="$t('form.email')"
                required
                v-model="requestData.email"
                :error-messages="emailErrors"
                hide-details="auto"
                color="success"
                v-on:keypress="validationForEmail($event)"
                @paste="validationForEmail($event)"
              ></VTextField>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                dense
                :label="$t('form.description')"
                required
                v-model="requestData.description"
                hide-details="auto"
                color="success"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider class="mx-5"></v-divider>
      <v-card-actions>
        <v-row class="my-0 py-0 container">
          <v-col cols="6" class="my-0 py-0">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              @click="showConfirmDialog"
              block
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </v-col>
          <v-col cols="6" class="my-0 py-0">
            <VBtn
              v-if="type === 'create'"
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onSuccess"
              :disabled="!isChanged || loading"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.create") }}
            </VBtn>
            <VBtn
              v-else
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onUpdate"
              :disabled="!isChanged || loading"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.update") }}
            </VBtn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import phoneMask from "@/validators/phoneMask";
import {
  validationForEmail,
  validationForName,
  validationForPhoneNumber
} from "@/mixins/helpers";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import EventBus from "@/events/EventBus";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  data: () => ({
    isChanged: false,
    loading: false,
    visibleConfirm: false,
    requestData: {
      name: "",
      phone: "",
      description: "",
      id_state: "3000",
      email: ""
    },
    statusOptions: [
      { text: 'HOT', value: 'HOT' },
      { text: 'WARM', value: 'WARM' },
      { text: 'COLD', value: 'COLD' },
    ],
    dateMenu: false,
  }),
  components: {
    StatusCircle,
    ConfirmLeftDialog
  },
  validations: {
    requestData: {
      name: { required },
      phone: { required, phoneMask },
      id_state: { required },
      email: { email }
    }
  },
  mounted() {
    if (this.request) {
      this.requestData = { ...this.request };
    }
    this.requestDataCopy = { ...this.requestData };
    if( this.type === 'create' ) {
      this.isChanged = false;
    }
  },
  mixins: [validationMixin, notifications],
  props: {
    request: {
      required: false,
      default: null
    },
    type: {
      required: true,
      default: "create"
    },
    states: {
      require: true
    },
    visible: {
      require: true
    },
    editRequestTitle: {
      required: false,
      default: true
    }
  },
  methods: {
    validationForEmail,
    validationForPhoneNumber,
    validationForName,
    getStatusColorClass(status) {
      switch (status) {
        case 'HOT':
          return 'hot';
        case 'WARM':
          return 'warm';
        case 'COLD':
          return 'cold';
        default:
          return '';
      }
    },
    onClose() {
      this.$emit("close");
      this.resetRequestData();
      this.$v.$reset();
    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    resetRequestData() {
      this.requestData = {
        name: "",
        phone: "",
        description: "",
        id_state: "3000",
        email: ""
      };
    },
    async onUpdate() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loading = true;
        const formData = new FormData();
        const changedData = this.$getChangedData(this.requestData, this.requestDataCopy);
        changedData.forEach(data => {
          formData.append(data.field, data.value);
        });
        formData.append("uuid", this.requestData.uuid);
        await joinRequestService.updateJoinRequest(formData);
        this.resetRequestData();
        this.loading = false;
        this.$v.$reset();
        EventBus.$emit("requests-modified");
        this.onClose();

        this.setSuccessNotification(this.$t("landing.notifications.successfully_updated"));
      } catch (e) {
        this.loading = false;

        console.log(e)
      }
    },
    async onSuccess() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loading = true;

        const formData = new FormData();
        const changedData = this.$getChangedData(this.requestData, this.requestDataCopy);
        changedData.forEach(data => {
          formData.append(data.field, data.value);
        });

        await joinRequestService.addJoinRequest(formData);
        this.onClose();

        this.loading = false;

        EventBus.$emit("requests-modified");

        this.setSuccessNotification(this.$t("landing.notifications.successfully_created"));
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    getItemsText(item) {
      return item[`${this.currentLanguage.key}_name`];
    }
  },
  watch: {
    request: {
      deep: true,
      handler(e) {
        this.requestData = e;
        this.requestDataCopy = { ...this.requestData };
      }
    },
    requestData: {
      deep: true,
      handler() {
        this.isChanged = !_.isEqual(this.requestDataCopy, this.requestData);
      }
    },
    visibility: {
      deep: true,
      handler() {
        if( this.type === 'create' ) {
          this.requestData.status = "HOT";
          this.requestData.time_last_contact = this.NextYear;
          this.isChanged = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);
      const formattedEvent = `${year}-${month}-${day}`;
      return formattedEvent;
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.requestData.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.requestData.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.requestData.name.$dirty) {
        return errors;
      }
      !this.$v.requestData.phone.phoneMask &&
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.requestData.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.requestData.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.requestData.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    }
  }
};
</script>

<style>
.status-marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.hot {
  background-color: #dd2e2e;
}

.warm {
  background-color: #4894d9;
}

.cold {
  background-color: lightblue;
}
</style>
