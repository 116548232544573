<template>
  <tr>
    <td class="text-center">
      <VBtn
        color="primary"
        small
        class="btn-edit"
        @click="onEdit(request)"
        :class="{ sm: !isLarge }"
      >
        <VIcon dense>mdi-pencil</VIcon>
      </VBtn>

      <VBtn :small="!isLarge" :large="isLarge" v-if="permissions.can_delete_join_requests" icon dense color="red" @click="deleteDialog = true">
        <VIcon>mdi-delete</VIcon>
      </VBtn>
    </td>
    <td>{{ request.name || "--" }}</td>
    <td class="text-center">{{ request.phone | VMask("+38 (###) ##-##-###") }}</td>
    <td class="text-center">{{ request.email || "--" }}</td>
    <!-- <td class="text-center">{{ request.id_state || "--" }}</td> -->
    <td class="md">
      <div class="d-flex align-center justify-content-start ">
        <StatusCircle :status-name="request.id_state" :type="'supply'" :class="{ sm: !isLarge }" />
        <div class="elispsis">
          {{ getStatusName(request) }}
        </div>
      </div>
    </td>
    <td class="md">
      <div class="d-flex align-center justify-content-start ">
        <div :class="getStatusColorClass(request.status)" class="status-marker"></div>
        <div class="elispsis">
          {{ request.status }}
        </div>
      </div>
    </td>
    <td class="text-center">
      {{ request.time_updated | createdAt(request.time_updated, currentLanguage) }}
    </td>
    <td class="text-center">
      {{ request.time_last_contact | createdAt(request.time_last_contact, currentLanguage) }}
    </td>
    <td class="text-center">
      {{ request.time_created | createdAt(request.time_created, currentLanguage) }}
    </td>
    <td class="text-center">
      <div class="d-flex align-center ml-2" v-if="request.uuid_user_created === '00000000-0000-0000-0000-000000000000'">
            <VIcon  class="mr-2" size="20" color="deep-purple" >mdi-account-plus</VIcon>
            <span style="color:#757575; font-weight: 500;">
              Заявка
            </span>
          </div>
      <div v-else class="d-flex">
        <div class="d-flex align-center ml-2" >
            <img width="28" class="mr-2 rounded-circle userAvatarDrop border" v-if="request?.user_details?.path_img_thumb"  :src="request?.user_details?.path_img_thumb" />
            <VIcon  class="mr-2 userAvatarDrop" size="28" v-else >mdi-account-circle</VIcon>
            <span style="color:#757575; font-weight: 500;">
              {{ request?.user_details?.name || "--" }}
            </span>
          </div>
      </div>
    </td>
      <ConfirmDialog
      :visible="deleteDialog"
      @close="deleteDialog = false"
      @onConfirm="onDelete();"
      @onCancel="deleteDialog = false"
      :typeAlert="'error'"
    >
    <template v-slot:header>
      {{$t('confirmDialog.accept_deleteTitle')}}
    </template>
    <template v-slot:description>
    {{$t('confirmDialog.accept_deleteDesc')}}
    </template>
    </ConfirmDialog>
    <RequestDialog type="edit" :states="states" v-if="showEditDialog"  :request="request" :visible="showEditDialog" @close="onCloseCreateRequest" />
  </tr>
</template>

<script>
import SizeUi from "@/mixins/SizeUi";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import RequestDialog from "../dialogs/RequestDialog.vue";
import ConfirmDialog from '@/components/dialog/ConfirmDialog.vue';
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";

export default {
  data:() => ({
    showEditDialog:false,
    deleteDialog: false,
  }),
  methods: {
    getStatusColorClass(status) {
      switch (status) {
        case 'HOT':
          return 'hot';
        case 'WARM':
          return 'warm';
        case 'COLD':
          return 'cold';
        default:
          return '';
      }
    },
    onEdit(request) {
      this.showEditDialog = true
    },
    onCloseCreateRequest(){
      this.showEditDialog = false
    },
    async onDelete() {
      await joinRequestService.deleteJoinRequest(this.request.uuid);
      EventBus.$emit('requests-modified');

      this.deleteDialog = false;
    },
    getStatusName(request) {
      const findedState = this.states.find(e => e.id === request.id_state);
      if (findedState) {
        return findedState[`${this.currentLanguage.key}_name`];
      } else {
        return "--";
      }
    }
  },
  components: {
    StatusCircle,
    RequestDialog,
    ConfirmDialog
  },
  computed: {
    ...mapGetters(["currentLanguage"])
  },
  props: {
    request: {
      require: true
    },
    states: {
      require: true
    }
  },
  mixins: [SizeUi, user]
};
</script>


<style scoped>
.userAvatarDrop {
  width: 23px;
  height: 23px;
  object-fit: cover;

}
.border{
  border:.7px solid #c2c2c2;
}
</style>

